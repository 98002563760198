import TheSAMCommandsPage from '@/views/sa_management/commands/TheSAMCommandsPage.vue'
import TheSAMDatabasePage from '@/views/sa_management/database/TheSAMDatabasePage.vue'
import TheGeneralSaManagementsPage from '@/views/sa_management/general/tabs/general/TheGeneralSaManagementsPage.vue'
import TheImportPage from '@/views/sa_management/import/TheImportPage.vue'

export const saManagementGeneralRoutes = [
  {
    path: '/sa-management',
    name: 'management_general_route',
    redirect: { name: 'sa_management_home_route' },
    children: [
      {
        path: '',
        component: TheGeneralSaManagementsPage,
        name: 'sa_management_home_route'
      },
      {
        path: 'commands',
        name: 'management_commands_route',
        component: TheSAMCommandsPage
      },
      {
        path: 'database',
        name: 'management_db_route',
        component: TheSAMDatabasePage
      },
      {
        path: 'import',
        name: 'management_import_route',
        component: TheImportPage
      }
    ]
  }
]
