<template>
  <div
    class="flex h-full w-full flex-col divide-y bg-white shadow sm:rounded-lg"
  >
    <div class="shrink-0 p-4">
      <h3 class="text-3xl font-bold leading-6 text-gray-900">Import</h3>
    </div>
    <div class="flex-1 p-4">
      <ImportComponent
        v-for="import_ in imports"
        :key="import_.id"
        :import_="import_"
        @updated="fetchImport"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ImportComponent from '@/views/sa_management/import/component/ImportComponent.vue'

export default {
  name: 'TheImportPage',
  components: { ImportComponent },
  data() {
    return {
      imports: []
    }
  },
  computed: {
    ...mapGetters({})
  },
  mounted() {
    this.fetchImport()
  },
  methods: {
    ...mapActions({
      processFetchImportListAction: 'saManagement/fetchImportList'
    }),
    fetchImport() {
      this.processFetchImportListAction().then(
        (response) => (this.imports = response.imports)
      )
    }
  }
}
</script>
