import apiClient from '@u/apiClient'

export default {
  fetchDumpList({}) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/sa-management/dump`)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async dumpDb({ commit, getters }, payload) {
    console.log('dumpDb')
    await apiClient
      .get(`/sa-management/dump/direct`)
      .then(async (response) => {
        // console.log('res', response)
        if (response.data.success) {
          payload.successCallback()
        }
      })
      .catch(async (reason) => {
        console.error('fetchDumpList', reason)
      })
  },

  async restoreDump({ commit, getters }, payload) {
    console.log('in restore')
    await apiClient
      .post(`/sa-management/dump/restore`, { dump: payload.dump })
      .then(async (response) => {
        if (response.status === 200) {
          payload.successCallback(response.data.key)
        }
      })
      .catch(async (reason) => {
        console.error('fetchDumpList', reason)
      })
  },

  fetchImportList({}) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/import`)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  startImport({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/import/${payload.import_id}/start`)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  getImportTask({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/import/${payload.import_id}/task/${payload.task_id}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  getImportTaskStatus({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/import/${payload.import_id}/task/${payload.task_id}/status`)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  deleteImportTask({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`/import/${payload.import_id}/task/${payload.task_id}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
