<template>
  <div class="flex flex-col rounded border p-4">
    <div class="flex flex-row">
      <div class="flex flex-1 flex-col gap-2">
        <div>
          <span class="rounded bg-primary px-1 py-0.5 text-contrast-primary">
            {{ importTask.status }}
          </span>
        </div>
        <div>{{ importTask.started_at }}-</div>
      </div>
      <div class="shrink-0">
        <button
          v-if="['waiting', 'running'].includes(importTask.status)"
          class="flex aspect-square w-8 items-center justify-center rounded-full bg-primary text-contrast-primary"
          @click="stop"
        >
          <FontAwesomeIcon
            :icon="['fas', 'circle-stop']"
            fixed-width
            size="lg"
          />
        </button>
        <button
          v-if="['error', 'waiting', 'finished'].includes(importTask.status)"
          class="flex aspect-square w-8 items-center justify-center rounded-full bg-red-600 text-white"
          @click="deletedImportTask"
        >
          <FontAwesomeIcon :icon="['fas', 'trash']" fixed-width />
        </button>
      </div>
    </div>
    <div v-if="batch" class="flex flex-col gap-2">
      <div class="flex flex-row">
        <div class="flex shrink-0 flex-row rounded border">
          <div class="border-r px-2 py-1">{{ batch.processedJobs }}</div>
          <div class="px-2 py-1">{{ batch.totalJobs }}</div>
        </div>
      </div>
      <div class="w-full rounded-full bg-gray-200 dark:bg-gray-700">
        <div
          class="rounded-full bg-blue-600 p-0.5 text-center text-xs font-medium leading-none text-blue-100"
          :style="{
            width: `${batch.progress}%`
          }"
        >
          {{ `${batch.progress} %` }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import { mapActions } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'ImportTaskComponent',
  components: { FontAwesomeIcon },
  props: {
    importTask: {
      type: Object,
      required: true
    }
  },
  emits: ['update:importTask', 'deleted'],
  data() {
    return {
      timeout: null,
      batch: null
    }
  },
  computed: {
    localImportTask: {
      get() {
        return this.importTask
      },
      set(value) {
        this.$emit('update:importTask', value)
      }
    }
  },
  unmounted() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions({
      processGetImportTaskAction: 'saManagement/getImportTask',
      processGetImportTaskStatusAction: 'saManagement/getImportTaskStatus',
      processDeleteImportTaskAction: 'saManagement/deleteImportTask'
    }),
    refresh() {
      if (this.importTask.status === 'running') {
        this.getStatus()
      } else if (this.importTask.status === 'waiting') {
        this.getImportTask()
      }
    },
    getStatus() {
      if (this.importTask.status === 'running') {
        this.processGetImportTaskStatusAction({
          import_id: this.importTask.import_id,
          task_id: this.importTask.id
        }).then((response) => {
          this.batch = response.batch
          if (this.batch.progress === 100) {
            this.processGetImportTaskAction({
              import_id: this.importTask.import_id,
              task_id: this.importTask.id
            }).then((response) => {
              this.localImportTask = response.import_task
            })
          }
        })
      }
      this.timeout = setTimeout(() => {
        this.refresh()
      }, 2000)
    },
    getImportTask() {
      if (this.importTask.status === 'waiting') {
        this.processGetImportTaskAction({
          import_id: this.importTask.import_id,
          task_id: this.importTask.id
        }).then((response) => {
          this.localImportTask = response.import_task
        })
      }
      this.timeout = setTimeout(() => {
        this.refresh()
      }, 5000)
    },
    deletedImportTask() {
      if (['error', 'waiting', 'finished'].includes(this.importTask.status)) {
        this.processDeleteImportTaskAction({
          import_id: this.importTask.import_id,
          task_id: this.importTask.id
        }).then((response) => {
          this.$emit('deleted', this.localImportTask.id)
        })
      }
    }
  }
}
</script>
