<template>
  <div class="flex flex-col rounded border">
    <div class="flex flex-row">
      <div class="flex flex-1 flex-col gap-2 p-4">
        <div>
          <span class="rounded bg-primary px-1 py-0.5 text-contrast-primary">
            {{ import_.type }}
          </span>
        </div>
        <div>{{ import_.settings }}</div>
      </div>
      <div class="shrink-0 p-4">
        <button
          class="flex aspect-square w-8 items-center justify-center rounded-full bg-primary text-contrast-primary"
          @click="startImport"
        >
          <FontAwesomeIcon
            :icon="['fas', 'circle-play']"
            fixed-width
            size="lg"
          />
        </button>
      </div>
    </div>
    <div v-if="import_.import_tasks.length" class="flex flex-col gap-2 p-4">
      <ImportTaskComponent
        v-for="(importTask, i) in import_.import_tasks"
        :key="importTask.id"
        v-model:import-task="import_.import_tasks[i]"
        @deleted="$emit('updated')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ImportTaskComponent from '@/views/sa_management/import/component/ImportTaskComponent.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'ImportComponent',
  components: { FontAwesomeIcon, ImportTaskComponent },
  props: {
    import_: {
      type: Object,
      required: true
    }
  },
  emits: ['updated'],
  methods: {
    ...mapActions({
      processStartImportAction: 'saManagement/startImport'
    }),
    startImport() {
      this.processStartImportAction({
        import_id: this.import_.id
      }).then((response) => {
        this.$emit('updated')
      })
    }
  }
}
</script>
